window.onload = function() {
  // iPhone Safari 画面タップでキーボード閉じる
  document.body.ontouchend = function() {
    document.activeElement.blur();
  };
}
// safari historyback -> onpageshow
window.onpageshow = function(event) {
  if(document.getElementsByTagName('nav')[0] != null) {
    document.getElementsByTagName('nav')[0].style.animation = '';
  }
};
// nav_on()
window.nav_on = function() {
  document.getElementsByTagName('nav')[0].style.animation='nav_on 0.3s forwards';
  document.getElementsByClassName('shadow')[0].style.animation='shadow_on 0.3s';
  document.getElementsByClassName('shadow')[0].style.display='block';
  document.getElementsByClassName('shadow')[0].addEventListener("click",function(){nav_off()});
};
// nav_off()
window.nav_off = function(href) {
  document.getElementsByTagName('nav')[0].style.animation='nav_off 0.3s';
  document.getElementsByClassName('shadow')[0].style.display='none';
  document.getElementsByClassName('shadow')[0].removeEventListener("click",function(){nav_off()});
  // setTimeout -> safari historyback -> 
  setTimeout(function(){ if(href != undefined){location.href=href} },300);
};
// modal_on()
window.modal_on = function(html,button,callback) {
  html = '<div class="modal"><div class="html">' + html + '</div>'
  if(typeof button === 'undefined') {
    document.getElementsByClassName('shadow')[0].innerHTML = html + '<div>'
  } else {
    document.getElementsByClassName('shadow')[0].innerHTML = html + '<button class="button">' + button + '</button><div>'
    document.getElementsByClassName('button')[0].addEventListener("click",function(){
      document.getElementsByClassName('shadow')[0].removeEventListener("click",modal_off);
      document.getElementsByClassName('modal')[0].style.display='none';
      callback();
      return false;
    });
  }

  document.getElementsByClassName('modal')[0].style.animation='modal_on 0.3s';
  document.getElementsByClassName('modal')[0].style.display='block';
  document.getElementsByClassName('shadow')[0].style.animation='shadow_on 0.3s';
  document.getElementsByClassName('shadow')[0].style.display='flex';
  document.getElementsByClassName('shadow')[0].addEventListener("click",modal_off);
};
// modal_off()
window.modal_off = function() {
  document.getElementsByClassName('shadow')[0].style.display='none';
  document.getElementsByClassName('modal')[0].style.display='none';
};
